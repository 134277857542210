<template>
  <div>
    <div class="row items-center">
      <q-select
        data-testid="vehicle-selection-dropdown"
        :model-value="selectedVehicleId"
        filled
        label="Search by Vehicle ID (Gen 3+ only)"
        label-color="grey-4"
        dropdown-icon="arrow_drop_down"
        clear-icon="cancel"
        :options="vehicleOptions"
        option-label="vehicle_id"
        option-value="vehicle_id"
        use-input
        input-debounce="300"
        clearable
        style="width: 100%"
        :loading="vehiclesStore.vehiclesLoading"
        @filter="filterVehicles"
        @clear="onClearInput"
        @focus="onFocusInput"
        @blur="onBlurInput"
        @update:model-value="selectVehicle"
      >
        <template #append>
          <q-btn icon="refresh" flat dense @click="refreshVehicleList(true)">
            <q-tooltip>Refresh Vehicle List</q-tooltip>
          </q-btn>
        </template>

        <template #after>
          <q-icon name="local_shipping" cursor="help">
            <q-tooltip v-if="tooltipText.length > 0" @show="onShowVehInfoTooltip">
              <div v-for="line in tooltipText" :key="line">{{ line }}</div>
            </q-tooltip>
            <q-tooltip v-else> No vehicle details available. </q-tooltip>
          </q-icon>
        </template>

        <FloatingHint ref="floatingHint" :show-delay-ms="5000">
          Start by selecting a vehicle
        </FloatingHint>
      </q-select>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, watch, useTemplateRef } from 'vue'
import { Vehicle } from 'src/models'
import { useVehiclesStore, useConfigStore, useTimeUpdateStore } from 'src/stores'
import { DatadogRumElement, DatadogRumInput, DatadogRumMethod } from 'src/models'
import { datadogRum } from '@datadog/browser-rum'
import FloatingHint from 'src/components/FloatingHint.vue'

const vehiclesStore = useVehiclesStore()
const configStore = useConfigStore()
const timeUpdateStore = useTimeUpdateStore()
const vehicleOptions = ref<Vehicle[]>([])
const tooltipText = ref<string[]>([])

const emit = defineEmits(['vehicleInputFocused', 'vehicleInputBlurred'])

const floatingHint = useTemplateRef('floatingHint')

// Updates type ahead in the search input
function filterVehicles(val: string, update: (callback: () => void) => void): void {
  update(() => {
    vehicleOptions.value = vehiclesStore.vehiclesList.filter((vehicle: Vehicle) =>
      vehicle.vehicle_id.toLocaleLowerCase().includes(val.toLocaleLowerCase())
    )
  })
}

// Selects the vehicle_id
const selectedVehicleId = computed(() => {
  return vehiclesStore.selectedVehicle?.vehicle_id || ''
})

watch(selectedVehicleId, (newVehicleId, oldVehicleId) => {
  if (newVehicleId !== oldVehicleId) {
    const searchURL = new URL(window.location.href)

    if (newVehicleId === '' || newVehicleId === undefined) {
      searchURL.searchParams.delete(configStore.vehicleIdUrlParam)
    } else {
      searchURL.searchParams.set(configStore.vehicleIdUrlParam, newVehicleId)
    }
    window.history.replaceState({}, '', searchURL)
  }
})

// Selects a vehicle from the dropdown list and updates tooltip text
async function selectVehicle(vehicle: Vehicle | null) {
  if (vehicle) {
    tooltipText.value = concatenateVehicleValues(vehicle)
    vehiclesStore.setSelectedVehicleId(vehicle.vehicle_id)
    await timeUpdateStore.activateLiveMode()
  } else {
    vehiclesStore.resetSelectedVehicle()
    resetToDefaultVehicleOptions()
    timeUpdateStore.activateNoneMode()
  }
}

// Refreshes the vehicle list and clears the selected vehicle and tooltip text
async function refreshVehicleList(fromClick: boolean) {
  vehiclesStore.setVehiclesLoading(true)
  await vehiclesStore.updateVehiclesList()
  vehicleOptions.value = vehiclesStore.vehiclesList
  vehiclesStore.setVehiclesLoading(false)

  if (fromClick) {
    datadogRum.addAction('Refresh Vehicle List btn', {
      element: DatadogRumElement.RefreshVehicleListBtn,
      method: DatadogRumMethod.ButtonClick,
      input: DatadogRumInput.MouseTouchpad,
    })
  }
}

// Gets the vehicle list on mount
onMounted(async () => {
  await refreshVehicleList(false)
  floatingHint.value?.show() // eslint-disable-line @typescript-eslint/no-unsafe-call
})

vehiclesStore.$subscribe((_ /*mutation*/, state) => {
  if (state.selectedVehicleId) {
    floatingHint.value?.hide() // eslint-disable-line @typescript-eslint/no-unsafe-call
  }
})

// Helper function to concatenate vehicle values into an array for tooltip display
function concatenateVehicleValues(vehicle: Vehicle): string[] {
  const values = [
    `Vehicle id: ${vehicle.vehicle_id}`,
    vehicle.name ? `Name: ${vehicle.name}` : undefined,
    `Description: ${vehicle.description}`,
    `Gen: ${vehicle.gen}`,
    `Serial: ${vehicle.serial}`,
    `VIN: ${vehicle.vin}`,
    `Fleet: ${vehicle.fleet}`,
    `Status: ${vehicle.status}`,
    `Connected: ${vehicle.connected ? 'Connected' : 'Disconnected'}`,
    `Last updated: ${new Date(vehicle.last_updated * 1000).toUTCString()}`,
  ]
  return values.filter((value): value is string => value !== undefined)
}

function onClearInput() {
  resetToDefaultVehicleOptions()

  datadogRum.addAction('Vehicle Selection input cleared', {
    element: DatadogRumElement.VehicleSelectionInput,
    method: DatadogRumMethod.InputClick,
    input: DatadogRumInput.MouseTouchpad,
    action: 'Cleared',
  })
}

function onFocusInput() {
  emit('vehicleInputFocused')

  floatingHint.value?.hide() // eslint-disable-line @typescript-eslint/no-unsafe-call

  datadogRum.addAction('Vehicle Selection input focused', {
    element: DatadogRumElement.VehicleSelectionInput,
    method: DatadogRumMethod.InputClick,
    input: DatadogRumInput.MouseTouchpad,
    action: 'Focused',
  })
}

function onBlurInput() {
  emit('vehicleInputBlurred')

  if (!selectedVehicleId.value) {
    floatingHint.value?.show() // eslint-disable-line @typescript-eslint/no-unsafe-call
  }
}

// Resets the vehicle list, selected vehicle, and tooltip text to default
function resetToDefaultVehicleOptions() {
  tooltipText.value = []
  vehiclesStore.resetSelectedVehicle()
}

function onShowVehInfoTooltip() {
  // Will only trigger once the tooltip is fully shown and animation is complete (~300ms)
  datadogRum.addAction('Show Vehicle Info tooltip', {
    element: DatadogRumElement.VehicleInfoTooltip,
    method: DatadogRumMethod.ButtonHover,
    input: DatadogRumInput.MouseTouchpad,
  })
}
</script>
