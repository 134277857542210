<template>
  <Navbar
    @faults-selected="onTabSelected(NavigationPage.ROOT_CAUSES)"
    @trace-selected="onTabSelected(NavigationPage.TRACE)"
    @sensors-selected="onTabSelected(NavigationPage.SENSORS)"
    @docs-selected="onTabSelected(NavigationPage.DOCUMENTATION)"
  />

  <ActionBar v-show="currentPage !== NavigationPage.DOCUMENTATION" />

  <!-- Page with content -->
  <RootCauses v-if="currentPage === NavigationPage.ROOT_CAUSES" />
  <Trace v-if="currentPage === NavigationPage.TRACE" />
  <Sensors v-if="currentPage === NavigationPage.SENSORS" />
  <Documentation v-if="currentPage === NavigationPage.DOCUMENTATION" />

  <PermissionChecker />
</template>

<script lang="ts" setup>
import { ref, onBeforeMount, onMounted, onUnmounted } from 'vue'
import { useQuasar, Notify } from 'quasar'

import Navbar from 'src/layouts/Navbar.vue'
import ActionBar from 'src/layouts/ActionBar.vue'
import RootCauses from 'src/pages/RootCauses.vue'
import Trace from 'src/pages/Trace.vue'
import Sensors from 'src/pages/Sensors.vue'
import Documentation from 'src/pages/Documentation.vue'
import PermissionChecker from 'src/components/PermissionChecker.vue'
import { sanitize } from './stores/util'

import {
  useTimeUpdateStore,
  useNavigationStore,
  useUsersStore,
  useVehiclesStore,
  useConfigStore,
} from 'src/stores'
import { NavigationPage } from 'src/models'

const currentPage = ref(NavigationPage.ROOT_CAUSES)

const vehiclesStore = useVehiclesStore()
const timeUpdateStore = useTimeUpdateStore()
const configStore = useConfigStore()

const quasar = useQuasar()
const initialTheme = quasar.dark.mode

const navigationStore = useNavigationStore()

function onTabSelected(newCurrentPage: NavigationPage) {
  navigationStore.isDocsPageOpen = newCurrentPage === NavigationPage.DOCUMENTATION
  currentPage.value = newCurrentPage
}

async function loadSelections(location: Location) {
  // These can't be done in their respective components because in order to activate a timestamp,
  // there must also be a vehicle selected
  vehiclesStore.setVehiclesLoading(true)
  await vehiclesStore.updateVehiclesList()
  vehiclesStore.setVehiclesLoading(false)

  // Create URL and sanitize the parameters
  const currentURL = new URL(location.href)
  const sanitizedVehicleId = sanitize(currentURL.searchParams.get(configStore.vehicleIdUrlParam))
  const sanitizedTs = sanitize(currentURL.searchParams.get(configStore.faultTimestampUrlParam))

  // Vars to hold references to selections from the URL
  let validVehicleId = false
  let tsToActivate = undefined

  // If there's a vehicle id in the URL, check if it's valid
  if (sanitizedVehicleId) {
    validVehicleId = validateVehicleId(sanitizedVehicleId)
  }

  // If there's a timestamp in the URL, strip any non-number characters and check if it's valid
  if (sanitizedTs) {
    tsToActivate = parseAndValidateTs(sanitizedTs)
  }

  // Intended behaviors
  //    - Valid vehicle, valid timestamp:
  //        Select given vehicle
  //        Enter Playback mode
  //        Activate fault at given timestamp
  //    - Valid vehicle, invalid (or no provided) timestamp:
  //        Select given vehicle
  //        Enter Live mode for the selected vehicle
  //    - Invalid vehicle:
  //        Do not select a vehicle
  //        Do not enter any modes
  //        (This is the default start up behavior)
  if (validVehicleId) {
    vehiclesStore.setSelectedVehicleId(sanitizedVehicleId)
    if (tsToActivate) {
      timeUpdateStore.activatePlaybackMode()
      await timeUpdateStore.activateFaultEventTimestamp(tsToActivate)
    } else {
      await timeUpdateStore.activateLiveMode()
    }
  }
}

function validateVehicleId(sanitizedVehicleId: string): boolean {
  const isVehicleFound = vehiclesStore.vehiclesList.some(
    (vehicle) => vehicle.vehicle_id === sanitizedVehicleId
  )

  if (!isVehicleFound) {
    console.warn(`Invalid vehicle id provided in URL query params (${sanitizedVehicleId})`)
    Notify.create({
      type: 'negative',
      icon: 'warning',
      message: `Could not find any vehicle ids that match the URL parameter (${sanitizedVehicleId})`,
    })
  }
  return isVehicleFound
}

function parseAndValidateTs(sanitizedTs: string): number | undefined {
  let isValid = false
  const parsedTs = parseInt(sanitizedTs, 10)

  // Try to turn the timestamp into a date. If it works, it's a valid timestamp.
  if (!isNaN(parsedTs)) {
    try {
      const urlTsIsoString = new Date(parsedTs).toISOString()
      if (urlTsIsoString) {
        isValid = true
      }
    } catch (RangeError) {
      console.warn(
        `Invalid timestamp provided in URL query params (${sanitizedTs}). Timestamp will not be activated`
      )
    }
  }

  // If the parsedTs is not the same length as the original after converting it back to a string,
  // it means the timestamp contained non-number characters which parseInt removed. In this case,
  // assume that the timestamp is invalid. Note that because something like 1234 is still technically
  // a valid timestamp, this check has to happen AFTER trying to turn it into a date.
  const parsedTsString = parsedTs.toString()
  if (parsedTsString.length !== sanitizedTs.length) {
    console.warn(
      'Timestamp provided in URL contained invalid characters. Timestamp will not be activated'
    )
    isValid = false
  }
  if (isValid) {
    return parsedTs
  } else {
    return undefined
  }
}

onBeforeMount(() => {
  quasar.dark.set(true)
})

onMounted(async () => {
  await useUsersStore().initUser()

  await loadSelections(window.location)
})

onUnmounted(() => {
  quasar.dark.set(initialTheme)

  timeUpdateStore.deactivateLiveModeTicker()
})
</script>
