import { datadogLogs, StatusType } from '@datadog/browser-logs'
import { AxiosResponse } from 'axios'
import { DatadogLogKeys, DatadogService, DatadogLogMessages, DatadogMetricType } from 'src/models'

/**
 * Logs reliability metrics regarding API response times when fetching data.
 *
 * @param {Date} startTime - The timestamp when the request was started.
 * @param {AxiosResponse} response - The API or error response that was received.
 * @param {DatadogLogKeys} logKey - The type of Datadog log/event. See src/models/datadog.ts.
 * @param {DatadogService} serviceName - The name of the API/Service the response came from.
 */
export function LogDatadogFetchElapsedTime(
  startTime: Date,
  response: AxiosResponse,
  logKey: DatadogLogKeys,
  serviceName: DatadogService
) {
  const diff = new Date().getTime() - startTime.getTime()
  if (diff < 0) {
    return
  }

  const messageContext = {
    elapsed_milliseconds: diff,
    status: response.status,
    statusText:
      response.status >= 400
        ? response.statusText || (response.data as Record<string, unknown>)
        : response.statusText,
    url: response.config?.url ?? 'Unknown URL',
    log_key: logKey,
  }

  // Log different severities: Info (under 2 seconds), Warning (over 4 seconds)
  // Error severity currently not used due to alerting on all errors
  const severity = diff < 2000 ? 'info' : 'warn'
  LogDataDogMessage(
    DatadogMetricType.RELIABILITY,
    serviceName,
    `${DatadogLogMessages[logKey]} ${diff}ms`,
    severity,
    messageContext
  )
}

/**
 * Logs a message to DataDog with the specified metric type, feature, message, status, and context.
 *
 * @param metricType - The type of metric being logged (e.g., RELIABILITY, PERFORMANCE).
 * @param feature - The feature or component related to the log message.
 * @param log - The main content of the log message.
 * @param status - The status level of the log (info, warn, or error).
 * @param messageContext - Optional. Additional context to be included with the log message.
 *
 * @returns void This function doesn't return a value.
 */
export function LogDataDogMessage(
  metricType: DatadogMetricType,
  feature: string,
  log: string,
  status: StatusType,
  messageContext: object
) {
  const message = GetDatadogLogMessage(metricType, feature, log)
  const context = {
    ...messageContext,
    metric_type: metricType,
    feature: feature,
    log: log,
  }
  if (status === 'info') {
    datadogLogs.logger.info(message, { healthviz: context })
  } else if (status === 'warn') {
    datadogLogs.logger.warn(message, { healthviz: context })
  } else if (status === 'error') {
    datadogLogs.logger.error(message, { healthviz: context })
  }
}
/**
 * Logs the usage of the fault buffer to DataDog.
 * This function helps track whether the fault buffer was used or if external API was needed.
 *
 * @param {boolean} used - Indicates whether the fault buffer was used.
 *                         True if the buffer was used, false if external API was needed.
 * @returns {void} This function doesn't return a value.
 */
export function LogFaultBufferUsage(used: boolean) {
  const logKey = used ? DatadogLogKeys.FAULT_BUFFER_USED : DatadogLogKeys.FAULT_BUFFER_NOT_USED
  LogDataDogMessage(
    DatadogMetricType.PERFORMANCE,
    'fault-buffer',
    DatadogLogMessages[logKey],
    'info',
    {
      log_key: logKey,
    }
  )
}

/**
 * Formats a log message with metric type and feature information.
 *
 * @param metricType - The type of metric being logged (e.g., RELIABILITY, PERFORMANCE).
 * @param feature - The feature or component related to the log message.
 * @param message - The main content of the log message.
 * @returns A formatted string containing the metric type, feature, and message.
 */
export function GetDatadogLogMessage(
  metricType: DatadogMetricType,
  feature: string,
  message: string
): string {
  return `[${metricType}] [${feature}] ${message}`
}
