<template>
  <q-table
    flat
    bordered
    title="Sensors with Faults"
    :rows="sensorRows"
    :columns="sensorCols"
    :pagination="pagination"
    row-key="component_id"
    @update:pagination="onPaginationUpdate"
  >
    <template #header="props">
      <q-tr :props="props">
        <q-th auto-width />
        <q-th v-for="col in props.cols" :key="col.name" :props="props">
          {{ col.label }}
        </q-th>
      </q-tr>
    </template>
    <template #body="props">
      <q-tr :props="props">
        <q-td auto-width>
          <q-btn
            size="md"
            color="primary"
            round
            dense
            :icon="props.expand ? 'remove' : 'add'"
            @click="onExpandRow(props)"
          />
        </q-td>
        <q-td v-for="col in props.cols" :key="col.name" :props="props">
          {{ col.value }}
        </q-td>
      </q-tr>
      <q-tr v-show="props.expand" :props="props">
        <q-td colspan="100%">
          <!-- Display Error Information -->
          <div class="text-left">
            <q-list>
              <q-item>
                <q-item-section>
                  <q-item-label><b>Error Code</b></q-item-label>
                  <q-item-label caption>{{ props.row.error_code }}</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label><b>Fault Type</b></q-item-label>
                  <q-item-label caption>{{ props.row.fault_type }}</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label><b>Verified</b></q-item-label>
                  <q-item-label caption>{{ props.row.verified }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label><b>Error ID</b></q-item-label>
                  <q-item-label caption>{{ props.row.error_id }}</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label><b>Error Source</b></q-item-label>
                  <q-item-label caption>{{ props.row.error_source }}</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label><b>Error Topic</b></q-item-label>
                  <q-item-label caption>{{ props.row.error_topic }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script lang="ts" setup>
import { SensorRow } from 'src/models/sensor'
import { useSensorFaultStore } from 'src/stores'
import { computed, ref, Ref } from 'vue'
import { QTableProps } from 'quasar'
import { QTableRowProp, QTablePagination } from 'src/models'
import { DatadogRumElement, DatadogRumInput, DatadogRumMethod } from 'src/models'
import { datadogRum } from '@datadog/browser-rum'

const sensorFaultStore = useSensorFaultStore()

// Required to trigger @update:pagination
const pagination: Ref<QTablePagination | undefined> = ref()
let prevPagination: QTablePagination | undefined = undefined

const notReported = 'Not Reported'

// The list of Sensor errors to ultimately display
const sensorRows = computed<SensorRow[] | never[]>(() => {
  let rows: SensorRow[] = []
  sensorFaultStore.activeSensorFaults.forEach((sensorFault) => {
    rows.push({
      component_id: sensorFault.component_id || notReported,
      component_name: sensorFault.component_name || notReported,
      item_name: sensorFault.item_name || notReported,
      item_type: sensorFault.item_type || notReported,
      error_id: sensorFault.error.id || notReported,
      error_source: sensorFault.error.source || notReported,
      error_topic: sensorFault.error.topic || notReported,
      error_code: sensorFault.error_code || notReported,
      verified: sensorFault.verified || notReported,
      fault_type: sensorFault.fault_type || notReported,
    })
  })
  return rows
})

const sensorCols: QTableProps['columns'] = [
  {
    name: 'componentId',
    align: 'left',
    required: true,
    label: 'Sensor ID',
    field: (row: SensorRow) => row.component_id,
    sortable: true,
  },
  {
    name: 'componentName',
    align: 'center',
    label: 'Sensor Name',
    field: (row: SensorRow) => row.component_name,
    sortable: true,
  },
  {
    name: 'itemName',
    align: 'center',
    label: 'Item Name',
    field: (row: SensorRow) => row.item_name,
    sortable: true,
  },
  {
    name: 'itemType',
    align: 'center',
    label: 'Item Type',
    field: (row: SensorRow) => row.item_type,
    sortable: true,
  },
]

function onExpandRow(props: QTableRowProp) {
  props.expand = !props.expand

  datadogRum.addAction('Sensors Expand Row btn', {
    element: DatadogRumElement.SensorsExpandRowBtn,
    method: DatadogRumMethod.ButtonClick,
    input: DatadogRumInput.MouseTouchpad,
    action: props.expand ? 'Row expanded' : 'Row collapsed',
  })
}

function onPaginationUpdate(newPagination?: QTablePagination) {
  if (!newPagination) {
    return
  }

  // Skip DD logging for first pagination change due to it occurring on component mount
  if (!prevPagination) {
    prevPagination = newPagination
    return
  }

  // Figure out exactly what's changed (if anything) and log it
  if (
    prevPagination.sortBy !== newPagination.sortBy ||
    prevPagination.descending !== newPagination.descending
  ) {
    let sortAction = 'no sort'
    if (newPagination.sortBy) {
      sortAction = `${newPagination.sortBy} ${newPagination.descending ? 'desc' : 'asc'}`
    }

    datadogRum.addAction('Sensors Table Row Header sorting', {
      element: DatadogRumElement.SensorsTableRowHeader,
      method: DatadogRumMethod.InputClick,
      input: DatadogRumInput.MouseTouchpad,
      action: `New sort: ${sortAction}`,
    })
  }

  // Check rowsPerPage first and make exclusive because when it changes, the page gets reset to 1
  if (prevPagination.rowsPerPage !== newPagination.rowsPerPage) {
    datadogRum.addAction('Sensors Table Pages Per Row dropdown', {
      element: DatadogRumElement.SensorsTableRowsPerPageDropdown,
      method: DatadogRumMethod.InputClick,
      input: DatadogRumInput.MouseTouchpad,
      action: `Rows per page: ${newPagination.rowsPerPage}`,
    })
  } else if (prevPagination.page !== newPagination.page) {
    datadogRum.addAction('Sensors Table Page btn', {
      element: DatadogRumElement.SensorsTablePageBtn,
      method: DatadogRumMethod.InputClick,
      input: DatadogRumInput.MouseTouchpad,
      action: `On page: ${newPagination.page}`,
    })
  }

  prevPagination = newPagination
}
</script>
