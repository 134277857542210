// Datadog RUM user action tracking: elements
export enum DatadogRumElement {
  // Time Navigation elements
  TogglePlaybackBtn = 'Toggle Playback btn',
  RewindFrameBtn = 'Rewind frame btn',
  ForwardFrameBtn = 'Forward frame btn',
  JumpToTimeOpenBtn = 'Jump to Time Open btn',
  JumpToTimeCancelBtn = 'Jump to Time Cancel btn',
  JumpToTimeJumpBtn = 'Jump to Time Jump btn',
  CopyTimestampBtn = 'Copy Timestamp btn',
  // Vehicle Selection elements
  VehicleSelectionInput = 'Vehicle Selection input',
  RefreshVehicleListBtn = 'Refresh Vehicle List btn',
  VehicleInfoTooltip = 'Vehicle Information tooltip',
  // HM Trace elements
  SavePNGBtn = 'Save PNG btn',
  CopyPNGBtn = 'Copy PNG btn',
  SaveSVGBtn = 'Save SVG btn',
  RotateGraphBtn = 'Rotate Graph btn',
  // Sensors elements
  SensorsTableRowHeader = 'Sensors Table Row Header',
  SensorsTablePageBtn = 'Sensors Table Page btn',
  SensorsTableRowsPerPageDropdown = 'Sensors Table Rows Per Page dropdown',
  SensorsExpandRowBtn = 'Sensors Expand Row btn',
  // Navbar button elements
  NavbarLogoBtn = 'Navbar Logo btn',
  NavbarFaultsBtn = 'Navbar Faults btn',
  NavbarTraceBtn = 'Navbar Trace btn',
  NavbarSensorsBtn = 'Navbar Sensors btn',
  NavbarDocsBtn = 'Navbar Docs btn',
}

// Datadog RUM user action tracking: methods
export enum DatadogRumMethod {
  ButtonClick = 'Button click',
  ButtonHover = 'Button hover',
  InputClick = 'Input click',
  // A mouse click that wasn't targeted towards a specific element. Ex: clicking a dialog background
  NontargetedMouseClick = 'Nontargeted mouse click',
  Keybind = 'Keybind',
  Unknown = 'Unknown',
}

// Datadog RUM user action tracking: user inputs
export enum DatadogRumInput {
  MouseTouchpad = 'Mouse / Touchpad',
  TogglePlaybackKeybind = 'W',
  RewindKeybind = 'Q',
  ForwardKeybind = 'E',
  EscKey = 'Esc',
  Unknown = 'Unknown',
}

export type DatadogService = 'users-api' | 'vehicles-api' | 'health-monitoring-api'

/**
 * Enum representing various log keys used in the application.
 * These keys are used to identify different types of logs or events.
 *
 * @enum {string}
 */
export enum DatadogLogKeys {
  /** Key for logging the latest health monitoring data */
  HEALTH_MON_LATEST = 'HEALTH_MON_LATEST',
  /** Key for logging the list of health monitoring data */
  HEALTH_MON_LIST = 'HEALTH_MON_LIST',
  /** Key for logging 5xx errors from the health monitoring latest endpoint */
  HEALTH_MON_LATEST_5xx_ERROR = 'HEALTH_MON_LATEST_5xx_ERROR',
  /** Key for logging 5xx errors from the health monitoring list endpoint */
  HEALTH_MON_LIST_5xx_ERROR = 'HEALTH_MON_LIST_5xx_ERROR',
  /** Key for logging when no vehicle data is available */
  VEHICLE_NO_DATA = 'VEHICLE_NO_DATA',
  /** Key for logging when vehicle data is returned */
  VEHICLE_FOUND = 'VEHICLE_FOUND',
  /** Key for logging 5xx errors related to vehicle data */
  VEHICLE_5xx_ERROR = 'VEHICLE_5xx_ERROR',
  /** Key for logging the user API data */
  USER_FETCHED = 'USER_FETCHED',
  /** Key for logging when user API error */
  USER_5xx_ERROR = 'USER_5xx_ERROR',
  /** Key for logging when the fault buffer is used. */
  FAULT_BUFFER_USED = 'FAULT_BUFFER_USED',
  /** Key for logging when the fault buffer is not used and we need fetch data. */
  FAULT_BUFFER_NOT_USED = 'FAULT_BUFFER_NOT_USED',
}

// Define the Record type for messages
type DatadogLogRecord = Record<DatadogLogKeys, string>

/**
 * A constant object that maps LogKeys to their corresponding log messages.
 * This object provides a centralized place to store and manage log messages used throughout the application.
 *
 * @constant
 * @type {DatadogLogRecord}
 *
 * @property {string} HEALTH_MON_LATEST - Message for logging the elapsed time when fetching the latest fault.
 * @property {string} HEALTH_MON_LIST - Message for logging the elapsed time when fetching a list of faults.
 * @property {string} HEALTH_MON_LATEST_5xx_ERROR - Message for logging 5xx errors when fetching the latest faults.
 * @property {string} HEALTH_MON_LIST_5xx_ERROR - Message for logging 5xx errors when fetching the list of faults.
 * @property {string} VEHICLE_NO_DATA - Message for logging when no vehicle data is available.
 * @property {string} VEHICLE_FOUND - Message for logging when a vehicle is found.
 * @property {string} VEHICLE_5xx_ERROR - Message for logging 5xx errors related to vehicle data.
 * @property {string} USER_FETCH - Message for logging the elapsed time when fetching a user.
 * @property {string} USER_5xx_ERROR - Message for logging 5xx errors in the Users API.
 * @property {string} FAULT_BUFFER_USED - Message for logging when the fault buffer is used for navigation.
 * @property {string} FAULT_BUFFER_NOT_USED - Message for logging when external API is used for fault navigation.
 */
export const DatadogLogMessages: DatadogLogRecord = {
  [DatadogLogKeys.HEALTH_MON_LATEST]: 'elapsed time fetching latest fault:',
  [DatadogLogKeys.HEALTH_MON_LIST]: 'elapsed time fetching list of faults:',
  [DatadogLogKeys.HEALTH_MON_LATEST_5xx_ERROR]:
    'server error 5xx from health monitoring api ($latest)',
  [DatadogLogKeys.HEALTH_MON_LIST_5xx_ERROR]: 'server error 5xx from health monitoring api (list)',
  [DatadogLogKeys.VEHICLE_NO_DATA]: 'no vehicles available',
  [DatadogLogKeys.VEHICLE_FOUND]: 'vehicle found',
  [DatadogLogKeys.VEHICLE_5xx_ERROR]: 'server error 5xx from vehicles api',
  [DatadogLogKeys.USER_FETCHED]: 'elapsed time fetching user',
  [DatadogLogKeys.USER_5xx_ERROR]: 'server error 5xx from users api',
  [DatadogLogKeys.FAULT_BUFFER_USED]: 'fault buffer used when navigating fault events',
  [DatadogLogKeys.FAULT_BUFFER_NOT_USED]: 'external API used when navigating fault events',
}

/**
 * Enum representing different types of metrics that can be logged.
 * These metric types are used to categorize and classify logged data.
 *
 * @enum {string}
 */
export enum DatadogMetricType {
  /** Represents metrics related to system reliability */
  RELIABILITY = 'RELIABILITY',
  /** Represents metrics related to system performance */
  PERFORMANCE = 'PERFORMANCE',
}
