import { Vehicle } from 'src/models'
import { ComputedRef, computed, ref } from 'vue'
import { useVehiclesApiStore } from 'src/stores'
import { Notify } from 'quasar'

export const vehiclesStoreInternal = () => {
  const vehiclesApiStore = useVehiclesApiStore()

  const vehiclesLoading = ref<boolean>(false)

  const vehiclesList = ref<Vehicle[] | []>([])
  const selectedVehicleId = ref<string | undefined>(undefined)

  const selectedVehicle: ComputedRef<Vehicle | undefined> = computed(() => {
    return vehiclesList.value?.find((vehicle) => vehicle.vehicle_id === selectedVehicleId.value)
  })

  /**
   * Set the ID of the selected vehicle to the given value.
   *
   * @param vehicleIdToSelect The ID to select.
   */
  function setSelectedVehicleId(vehicleIdToSelect: string) {
    let idExists = false
    if (vehiclesList.value !== undefined) {
      idExists = vehiclesList.value.some((vehicle) => vehicle.vehicle_id === vehicleIdToSelect)
    }
    if (!idExists) {
      Notify.create({
        type: 'negative',
        message: `No vehicle found with an ID of ${String(vehicleIdToSelect)}`,
      })
    } else {
      selectedVehicleId.value = vehicleIdToSelect
    }
  }

  /**
   * Resets the ID of the selected vehicle.
   */
  function resetSelectedVehicle() {
    selectedVehicleId.value = undefined
  }

  /**
   * Updates the list of vehicles
   */
  async function updateVehiclesList() {
    vehiclesList.value = (await vehiclesApiStore.fetchVehicles()) || []
  }

  /**
   * Set if the vehicles list is currently loading.
   *
   * @param isLoading If the vehicles list is currently loading.
   */
  function setVehiclesLoading(isLoading: boolean) {
    vehiclesLoading.value = isLoading
  }

  return {
    selectedVehicleId,
    selectedVehicle,
    vehiclesList,
    vehiclesLoading,
    setSelectedVehicleId,
    resetSelectedVehicle,
    updateVehiclesList,
    setVehiclesLoading,
  }
}
