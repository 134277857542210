import { computed, ref } from 'vue'
import { Mode } from 'src/models/navigation'
import { Notify } from 'quasar'
import { useConfigStore, useFaultsStore } from 'src/stores'
import { timeDifference } from 'src/stores/util'

/**
 * Store for managing time updates and modes.
 */
export const timeUpdateStoreInternal = () => {
  const currentMode = ref(Mode.NONE)
  const intervalId = ref<NodeJS.Timeout>()

  const configStore = useConfigStore()
  const faultsStore = useFaultsStore()

  const isNoModeActive = computed(() => currentMode.value === Mode.NONE)
  const isLiveModeActive = computed(() => currentMode.value === Mode.LIVE)

  /**
   * Updates the current mode.
   * @param {Mode} newMode - The new mode to set.
   */
  function updateMode(newMode: Mode) {
    if (Object.values(Mode).includes(newMode)) {
      currentMode.value = newMode
    } else {
      Notify.create({
        type: 'negative',
        message: `Invalid mode: ${newMode}`,
      })
    }
  }

  /**
   * Activates live mode and updates to the latest fault event.
   */
  function activateNoneMode() {
    deactivateLiveModeTicker()
    updateMode(Mode.NONE)
  }

  /**
   * Activates live mode and updates to the latest fault event.
   */
  async function activateLiveMode() {
    updateMode(Mode.LIVE)
    await faultsStore.updateActiveFaultToLatest()
    activateLiveModeTicker()
  }

  function activateLiveModeTicker() {
    intervalId.value = setInterval(() => {
      async function updateFault() {
        await faultsStore.updateActiveFaultToLatest()
      }

      updateFault().catch((error) => {
        console.error('Error updating fault to latest:', error)
      })
    }, configStore.liveModeFetchIntervalMilliseconds)
  }

  function deactivateLiveModeTicker() {
    clearInterval(intervalId.value)
  }

  /**
   * Activates playback mode.
   */
  function activatePlaybackMode() {
    deactivateLiveModeTicker()
    updateMode(Mode.PLAYBACK)
  }

  /**
   * Activates a fault event at a specific timestamp.
   * @param {number} timestamp - The timestamp of the fault event to activate.
   * @returns {Promise<boolean>} - True if the update was successful, false otherwise.
   */
  async function activateFaultEventTimestamp(timestamp: number): Promise<boolean> {
    if (!isValidTimestamp(timestamp)) {
      Notify.create({
        type: 'negative',
        message: `Invalid timestamp: ${timestamp}`,
      })
      return false
    }

    const [result, message, activated_ts] = await faultsStore.updateActiveFaultTimestamp(timestamp)

    if (!result) {
      Notify.create({
        type: 'warning',
        message,
      })
    } else if (result && message) {
      const timeDiff = timeDifference(activated_ts, timestamp, true)
      Notify.create({
        icon: 'info',
        color: 'teal',
        classes: 'text-white',
        timeout: 10000,
        actions: [
          {
            label: 'Dismiss',
            color: 'white',
            handler: () => {
              /* ... */
            },
          },
        ],
        multiLine: true,
        progress: true,
        html: true,
        message: `
          <div>${message}</div>
          <ul>
            <li><strong>Your requested time:</strong> ${new Date(timestamp).toISOString()}</li>
            <li><strong>Closest time found:</strong> ${new Date(activated_ts).toISOString()}</li>
            <li><strong>Time difference:</strong> ${timeDiff}</li>
          </ul>`,
      })
    }

    return result
  }

  /**
   * Activates the previous fault event.
   * @returns {Promise<boolean>} - True if the update was successful, false otherwise.
   */
  async function activateFaultEventPrevious(): Promise<boolean> {
    const [result, message, caption] = await faultsStore.updateActiveFaultPrevious()

    if (!result) {
      Notify.create({
        type: 'negative',
        message,
      })
    } else if (result && message) {
      Notify.create({
        icon: 'info',
        color: 'teal',
        message,
        caption,
      })
    }

    return result
  }

  /**
   * Activates the next fault event.
   * @returns {Promise<boolean>} - True if the update was successful, false otherwise.
   */
  async function activateFaultEventNext(): Promise<boolean> {
    const [result, message, caption] = await faultsStore.updateActiveFaultNext()

    if (!result) {
      Notify.create({
        type: 'negative',
        message,
      })
    } else if (result && message) {
      Notify.create({
        icon: 'info',
        color: 'teal',
        message,
        caption,
      })
    }

    return result
  }

  return {
    currentMode,
    isLiveModeActive,
    isNoModeActive,
    updateMode,
    activateNoneMode,
    activateLiveMode,
    activatePlaybackMode,
    activateFaultEventTimestamp,
    activateFaultEventPrevious,
    activateFaultEventNext,
    deactivateLiveModeTicker,
  }
}

/**
 * Checks if a given timestamp is valid.
 * @param {number} timestamp - The timestamp to validate.
 * @returns {boolean} - True if the timestamp is valid, false otherwise.
 */
function isValidTimestamp(timestamp: number): boolean {
  const date = new Date(timestamp)
  return !isNaN(date.getTime())
}
