import { ref, computed, ComputedRef } from 'vue'
import axios from 'axios'
import { LogAndNotifyAxiosError } from './util'
import { Notify } from 'quasar'
import { LogDataDogMessage, LogDatadogFetchElapsedTime } from 'src/helpers/datadog'
import { User, RolesWithPermission, DatadogMetricType, DatadogLogKeys } from 'src/models'

export const usersStoreInternal = () => {
  const user = ref<User | undefined>(undefined)

  const userHasRequiredPermission: ComputedRef<boolean | undefined> = computed(() => {
    return user.value?.display_roles.some((role) => RolesWithPermission.includes(role))
  })

  /**
   * Initialize current user
   */
  async function initUser() {
    user.value = await fetchUser()
  }

  /**
   * Retrieve and return the current user from API
   */
  async function fetchUser() {
    const USERS_API_BASE_URL = String(import.meta.env.VITE_USERS_API_URL)
    const url = `${USERS_API_BASE_URL}/v1/me`
    const startTime = new Date()

    try {
      const response = await axios.get<User>(url)
      LogDatadogFetchElapsedTime(startTime, response, DatadogLogKeys.USER_FETCHED, 'users-api')
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        LogAndNotifyAxiosError(error, 'Error occurred calling: ' + url)

        if (!error.response) {
          return
        }

        if (error.response.status >= 500 && error.response.status < 600) {
          LogDataDogMessage(
            DatadogMetricType.RELIABILITY,
            'users-api',
            `server error ${error.response.status}`,
            'error',
            {
              status: error.response.status,
              statusText:
                error.response.statusText || (error.response.data as Record<string, unknown>),
              url: error.response.config?.url ?? 'Unknown URL',
              log_key: DatadogLogKeys.USER_5xx_ERROR,
            }
          )
          LogDatadogFetchElapsedTime(
            startTime,
            error.response,
            DatadogLogKeys.USER_5xx_ERROR,
            'users-api'
          )
        } else {
          LogDatadogFetchElapsedTime(
            startTime,
            error.response,
            DatadogLogKeys.USER_FETCHED,
            'users-api'
          )
        }
      } else {
        console.error(error)
        Notify.create({
          type: 'negative',
          icon: 'warning',
          message: JSON.stringify(error),
        })
      }
    }
  }

  return {
    user,
    userHasRequiredPermission,
    initUser,
  }
}
