<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div v-if="sensorFaultStore.activeSensorFaults.length > 0" class="q-pa-lg">
    <SensorFaultsTable></SensorFaultsTable>
  </div>
  <NoFaultsPlaceholder v-else :displayed-entity="'sensor faults'" />
</template>

<script lang="ts" setup>
import SensorFaultsTable from 'src/components/SensorFaultsTable.vue'
import { useSensorFaultStore } from 'src/stores'
import NoFaultsPlaceholder from 'src/components/NoFaultsPlaceholder.vue'

const sensorFaultStore = useSensorFaultStore()
</script>
