<template>
  <div class="row">
    <div class="col-6">
      <div
        id="active-timestamp-display"
        class="row justify-center items-center text-center text-bold"
        style="height: 100%"
      >
        <div v-if="lastValidTimestamp" class="text-weight-bold" style="position: relative">
          <div :class="screen.gt.sm ? 'text-h6' : 'text-body1'">
            <span>
              <NumberFlow
                :value="lastValidTimestamp.getUTCFullYear()"
                :format="{ useGrouping: false }"
              />/<NumberFlow
                :value="lastValidTimestamp.getUTCMonth() + 1"
                :format="{ useGrouping: false }"
              />/<NumberFlow
                :value="lastValidTimestamp.getUTCDate()"
                :format="{ useGrouping: false }"
              />,
              <NumberFlow
                :value="lastValidTimestamp.getUTCHours()"
                :format="{ useGrouping: false, minimumIntegerDigits: 2 }"
              />:<NumberFlow
                :value="lastValidTimestamp.getUTCMinutes()"
                :format="{ useGrouping: false, minimumIntegerDigits: 2 }"
              />:<NumberFlow
                :value="lastValidTimestamp.getUTCSeconds()"
                :format="{ useGrouping: false, minimumIntegerDigits: 2 }"
              />.<NumberFlow
                :value="lastValidTimestamp.getUTCMilliseconds()"
                :format="{ useGrouping: false }"
              />
              UTC
            </span>
            <q-btn
              icon="content_copy"
              :size="screen.gt.sm ? 'sm' : 'xs'"
              padding="xs"
              flat
              rounded
              @click="onTimestampCopy"
            ></q-btn>
          </div>
          <div class="text-grey-5" :class="screen.gt.sm ? 'text-body1' : 'text-body2'">
            {{ timeDeltaPrefix }}
            <span v-show="timeDeltaNumber > 0"
              ><NumberFlow :value="timeDeltaNumber" :animated="!disableButtonLoading"
            /></span>
            {{ timeDeltaSuffix }}
          </div>
        </div>
        <div v-else class="text-grey-6" :class="screen.gt.sm ? 'text-body1' : 'text-body2'">
          No time selected
        </div>
      </div>
    </div>

    <div class="col-6 items-center row">
      <q-btn-group id="btn-group-time-navigation" unelevated spread class="col">
        <q-btn
          v-if="!timeUpdateStore.isLiveModeActive"
          icon="event"
          :loading="loadingJump"
          :disable="disableButtonNoModeActive || disableButtonLoading"
          :label="labelBtnJump"
          color="primary"
          stack
        >
          <q-tooltip v-if="disableButtonNoModeActive || disableButtonLoading">
            {{ disableButtonNoModeActive ? messsageNoModeActive : messsageLoading }}
          </q-tooltip>
          <q-popup-proxy
            cover
            transition-show="scale"
            transition-hide="scale"
            @before-show="onShowTimePicker"
            @before-hide="onHideTimePicker"
          >
            <q-input v-model="stagedJumpTime" filled input-class="text-center" class="q-mb-sm" />
            <div class="row q-gutter-sm q-px-sm items-start">
              <q-date
                v-model="stagedJumpTime"
                mask="YYYY-MM-DDTHH:mm:ss.SSSZ"
                flat
                @update:model-value="updateStagedJumpTime"
              />
              <q-time
                v-model="stagedJumpTime"
                format24h
                with-seconds
                mask="YYYY-MM-DDTHH:mm:ss.SSSZ"
                flat
                @update:model-value="updateStagedJumpTime"
              />
            </div>
            <div class="row items-center justify-end q-ma-sm">
              <q-btn id="time-cancel-btn" v-close-popup label="Cancel" flat @click="onCancelJump" />
              <q-btn id="time-jump-btn" v-close-popup label="Jump" flat @click="onTriggerJump" />
            </div>
          </q-popup-proxy>
        </q-btn>
        <q-btn
          v-if="!timeUpdateStore.isLiveModeActive"
          id="btn-time-navigation-previous"
          :disable="disableButtonNoModeActive || disableButtonLoading || disableButtonNoActiveFault"
          icon="fast_rewind"
          :loading="loadingPrevious"
          label="Back (Q)"
          color="primary"
          stack
          @click="onTriggerBack(true)"
        >
          <q-tooltip
            v-if="disableButtonNoModeActive || disableButtonLoading || disableButtonNoActiveFault"
          >
            {{
              disableButtonNoModeActive
                ? messsageNoModeActive
                : disableButtonLoading
                  ? messsageLoading
                  : messsageNoActiveFault
            }}
          </q-tooltip>
        </q-btn>
        <q-btn
          id="btn-time-navigation-play-pause"
          :icon="timeUpdateStore.isLiveModeActive ? 'pause' : 'play_arrow'"
          :label="labelBtnLive"
          :color="timeUpdateStore.isLiveModeActive ? 'secondary' : 'primary'"
          :disable="disableButtonNoModeActive || disableButtonLoading"
          stack
          @click="onTriggerPlayPause(true)"
        >
          <q-tooltip v-if="disableButtonNoModeActive || disableButtonLoading">
            {{ disableButtonNoModeActive ? messsageNoModeActive : messsageLoading }}
          </q-tooltip>
        </q-btn>
        <q-btn
          v-if="!timeUpdateStore.isLiveModeActive"
          :disable="disableButtonNoModeActive || disableButtonLoading || disableButtonNoActiveFault"
          icon="fast_forward"
          :loading="loadingNext"
          label="Next (E)"
          color="primary"
          stack
          @click="onTriggerNext(true)"
        >
          <q-tooltip
            v-if="disableButtonNoModeActive || disableButtonLoading || disableButtonNoActiveFault"
          >
            {{
              disableButtonNoModeActive
                ? messsageNoModeActive
                : disableButtonLoading
                  ? messsageLoading
                  : messsageNoActiveFault
            }}
          </q-tooltip>
        </q-btn>
        <FloatingHint ref="floatingHint" :show-delay-ms="5000">
          <div class="q-mb-sm">
            Click
            <q-btn icon="play_arrow" class="bg-green-8" :label="labelBtnLive" dense flat /> to see
            the latest fault event, or click
          </div>
          <div>
            <q-btn icon="event" class="bg-green-8" :label="labelBtnJump" dense flat /> to jump to a
            time and view historical fault events
          </div>
        </FloatingHint>
      </q-btn-group>
      <q-chip
        v-if="timeUpdateStore.isLiveModeActive"
        data-testid="live-mode-indicator"
        class="col-auto q-pa-lg"
      >
        <q-avatar :color="colorRedDot" />
        {{ screen.gt.lg ? 'Live mode active' : screen.gt.sm ? 'Live mode' : 'Live' }}
      </q-chip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onUnmounted, useTemplateRef, watch } from 'vue'
import { Notify, useQuasar } from 'quasar'
import { useKeypress } from 'vue3-keypress'
import NumberFlow from '@number-flow/vue'
import { datadogRum } from '@datadog/browser-rum'

import { useTimeUpdateStore, useFaultsStore, useVehiclesStore, useConfigStore } from 'src/stores'
import { relativeTimeFromDates } from 'src/stores/util'
import { DatadogRumElement, DatadogRumInput, DatadogRumMethod } from 'src/models'
import FloatingHint from 'src/components/FloatingHint.vue'

const props = defineProps<{
  vehicleInputFocused: boolean
}>()

const floatingHint = useTemplateRef('floatingHint')

const timeUpdateStore = useTimeUpdateStore()
const faultsStore = useFaultsStore()
const vehiclesStore = useVehiclesStore()
const configStore = useConfigStore()

const screen = useQuasar().screen

const redDotTimerIntervalId = ref<NodeJS.Timeout>()
const colorRedDot = ref('red')
const timeDeltaNumber = ref(0)
const timeDeltaPrefix = ref('')
const timeDeltaSuffix = ref('')
const timeDeltaIntervalId = ref<NodeJS.Timeout>()

const loadingJump = ref(false)
const loadingPrevious = ref(false)
const loadingNext = ref(false)

const messsageNoModeActive = 'Please select a vehicle'
const messsageLoading = 'Please wait for previous request to finish'
const messsageNoActiveFault = 'Please select a time'

const disableButtonNoModeActive = computed(() => timeUpdateStore.isNoModeActive)
const disableButtonLoading = computed(
  () => loadingJump.value || loadingPrevious.value || loadingNext.value
)
const disableButtonNoActiveFault = computed(() => faultsStore.activeFaultEventTimestamp === null)

const timePickerShown = ref(true)

const jumpTime = ref(getISOTimestamp())
const stagedJumpTime = ref(jumpTime.value)
const lastValidTimestamp = ref<Date | null>(null)
const lastKnownVehicle = ref<string | undefined>(undefined)

const displayedTimestamp = computed(() => {
  return new Date(faultsStore.activeFaultEventTimestamp || 0)
})

const labelBtnJump = computed(() => (screen.gt.md ? 'Jump to time' : 'Jump'))
const labelBtnLive = computed(() => {
  if (timeUpdateStore.isLiveModeActive) {
    return 'Pause (W)'
  } else {
    return screen.gt.md ? 'Go Live (W)' : 'Live (W)'
  }
})

function getISOTimestamp(timestamp?: string) {
  // Get the current time as an ISO timestamp or convert an existing timestamp to ISO format
  // This also converts other timezones to UTC and changes '+00:00' to 'Z'

  // Ternary is required because `new Date(undefined)` !== `new Date()` (with no params)
  return timestamp ? new Date(timestamp).toISOString() : new Date().toISOString()
}

function updateTimeDelta() {
  const timeDelta = lastValidTimestamp.value ? relativeTimeFromDates(lastValidTimestamp.value) : ''
  timeDeltaNumber.value = Number(timeDelta.replace(/\D/g, ''))
  if (timeDeltaNumber.value > 0) {
    const idx = timeDelta.indexOf(String(timeDeltaNumber.value))
    timeDeltaPrefix.value = timeDelta.slice(0, idx).trim()
    timeDeltaSuffix.value = timeDelta.slice(idx + String(timeDeltaNumber.value).length).trim()
  } else {
    timeDeltaPrefix.value = timeDelta
    timeDeltaSuffix.value = ''
  }
}

function areButtonsBackNextUsable(): boolean {
  return (
    !disableButtonNoModeActive.value &&
    !disableButtonLoading.value &&
    !disableButtonNoActiveFault.value
  )
}

function isButtonPlayPauseUsable(): boolean {
  return !disableButtonNoModeActive.value && !disableButtonLoading.value
}

function onShowTimePicker() {
  // Disable keybinds while time picker is open
  timePickerShown.value = false

  // Update jump time with either active fault event timestamp or current time
  jumpTime.value =
    faultsStore.activeFaultEventTimestamp !== null
      ? getISOTimestamp(new Date(faultsStore.activeFaultEventTimestamp).toISOString())
      : getISOTimestamp()
  stagedJumpTime.value = jumpTime.value

  datadogRum.addAction('Action Bar Open Jump to Time btn', {
    element: DatadogRumElement.JumpToTimeOpenBtn,
    method: DatadogRumMethod.ButtonClick,
    input: DatadogRumInput.MouseTouchpad,
  })
}

function onHideTimePicker() {
  // Re-enable keybinds while time picker is closed
  timePickerShown.value = true
}

function updateStagedJumpTime(timestamp: string | null) {
  if (timestamp) stagedJumpTime.value = getISOTimestamp(timestamp)
}

function onCancelJump() {
  datadogRum.addAction('Action Bar Jump to Time Cancel btn', {
    element: DatadogRumElement.JumpToTimeCancelBtn,
    method: DatadogRumMethod.ButtonClick,
    input: DatadogRumInput.MouseTouchpad,
  })
}

async function onTriggerJump() {
  loadingJump.value = true
  jumpTime.value = stagedJumpTime.value
  const jumpUnixTime = Date.parse(jumpTime.value)
  await timeUpdateStore.activateFaultEventTimestamp(jumpUnixTime)
  loadingJump.value = false

  datadogRum.addAction('Action Bar Jump to Time Jump btn', {
    element: DatadogRumElement.JumpToTimeJumpBtn,
    method: DatadogRumMethod.ButtonClick,
    input: DatadogRumInput.MouseTouchpad,
  })
}

async function onTriggerBack(buttonClicked: boolean) {
  loadingPrevious.value = true
  timeUpdateStore.activatePlaybackMode()
  await timeUpdateStore.activateFaultEventPrevious()
  loadingPrevious.value = false

  datadogRum.addAction('Action Bar Back btn', {
    element: DatadogRumElement.RewindFrameBtn,
    method: buttonClicked ? DatadogRumMethod.ButtonClick : DatadogRumMethod.Keybind,
    input: buttonClicked ? DatadogRumInput.MouseTouchpad : DatadogRumInput.RewindKeybind,
  })
}

async function onTriggerPlayPause(buttonClicked: boolean) {
  // Log datadog first to avoid any potential axios conflicts
  datadogRum.addAction('Action Bar Toggle Playback btn', {
    element: DatadogRumElement.TogglePlaybackBtn,
    method: buttonClicked ? DatadogRumMethod.ButtonClick : DatadogRumMethod.Keybind,
    input: buttonClicked ? DatadogRumInput.MouseTouchpad : DatadogRumInput.TogglePlaybackKeybind,
    action: timeUpdateStore.isLiveModeActive ? 'Playback mode activated' : 'Live mode activated',
  })

  if (timeUpdateStore.isLiveModeActive) {
    timeUpdateStore.activatePlaybackMode()
  } else {
    await timeUpdateStore.activateLiveMode()
  }
}

async function onTriggerNext(buttonClicked: boolean) {
  loadingNext.value = true
  timeUpdateStore.activatePlaybackMode()
  await timeUpdateStore.activateFaultEventNext()
  loadingNext.value = false

  datadogRum.addAction('Action Bar Forward btn', {
    element: DatadogRumElement.ForwardFrameBtn,
    method: buttonClicked ? DatadogRumMethod.ButtonClick : DatadogRumMethod.Keybind,
    input: buttonClicked ? DatadogRumInput.MouseTouchpad : DatadogRumInput.ForwardKeybind,
  })
}

async function onTimestampCopy() {
  try {
    const isoTimestamp = getISOTimestamp(displayedTimestamp.value.toISOString())
    await navigator.clipboard.writeText(isoTimestamp)
    Notify.create({
      message: `Timestamp copied! (${isoTimestamp})`,
      type: 'positive',
      icon: 'check_circle',
      timeout: 1500,
    })
  } catch (err) {
    console.error('Failed to copy timestamp: ', err)
    Notify.create({
      message: 'Error copying timestamp to clipboard',
      type: 'warning',
      icon: 'priority_high_black',
      timeout: 1500,
    })
  }

  datadogRum.addAction('Action Bar Copy Timestamp btn', {
    element: DatadogRumElement.CopyTimestampBtn,
    method: DatadogRumMethod.ButtonClick,
    input: DatadogRumInput.MouseTouchpad,
  })
}

/* Keybinds */
useKeypress({
  keyEvent: 'keyup',
  keyBinds: [
    {
      keyCode: 81, // key "q"
      success: async () => areButtonsBackNextUsable() && (await onTriggerBack(false)),
      preventDefault: false,
    },
    {
      keyCode: 87, // key "w"
      success: () => isButtonPlayPauseUsable() && onTriggerPlayPause(false),
      preventDefault: false,
    },
    {
      keyCode: 69, // key "e"
      success: async () => areButtonsBackNextUsable() && (await onTriggerNext(false)),
      preventDefault: false,
    },
  ],
  isActive: computed(() => timePickerShown.value && !props.vehicleInputFocused),
})

watch(timeUpdateStore, (_ /* oldState */, newState) => {
  if (newState.isLiveModeActive) {
    redDotTimerIntervalId.value = setInterval(() => {
      colorRedDot.value = colorRedDot.value === 'red' ? 'dark' : 'red'
    }, 1000)
  } else {
    clearInterval(redDotTimerIntervalId.value)
  }
})

faultsStore.$subscribe((_ /* mutation */, state) => {
  /* eslint-disable @typescript-eslint/no-unsafe-call */

  const searchURL = new URL(window.location.href)
  if (state.activeFaultEventTimestamp) {
    // Set the URL search param for easy copy/pasting
    searchURL.searchParams.set(
      configStore.faultTimestampUrlParam,
      state.activeFaultEventTimestamp.toString()
    )
    lastValidTimestamp.value = new Date(state.activeFaultEventTimestamp)

    updateTimeDelta()
    if (!timeDeltaIntervalId.value) {
      timeDeltaIntervalId.value = setInterval(() => {
        updateTimeDelta()
      }, 500)
    }

    floatingHint.value?.hide()
  } else {
    // There is no longer a valid or active fault event timestamp, so clear it out from the URL
    searchURL.searchParams.delete(configStore.faultTimestampUrlParam)

    if (timeUpdateStore.isNoModeActive) {
      lastValidTimestamp.value = null
    }

    clearInterval(timeDeltaIntervalId.value)
    timeDeltaIntervalId.value = undefined
    timeDeltaNumber.value = 0
    timeDeltaPrefix.value = ''
    timeDeltaSuffix.value = ''
  }
  window.history.replaceState({}, '', searchURL)

  /* eslint-enable @typescript-eslint/no-unsafe-call */
})

vehiclesStore.$subscribe((_ /* mutation */, state) => {
  /* eslint-disable @typescript-eslint/no-unsafe-call */

  if (state.selectedVehicleId && !faultsStore.activeFaultEventTimestamp) {
    floatingHint.value?.show()
  }
  if (!state.selectedVehicleId) {
    floatingHint.value?.hide()
  }

  if (lastKnownVehicle.value !== state.selectedVehicleId) {
    lastValidTimestamp.value = null
  }
  lastKnownVehicle.value = state.selectedVehicleId

  /* eslint-enable @typescript-eslint/no-unsafe-call */
})

onUnmounted(() => {
  clearInterval(redDotTimerIntervalId.value)
  clearInterval(timeDeltaIntervalId.value)
  timeDeltaIntervalId.value = undefined
})
</script>
